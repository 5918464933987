jQuery(function ($){

  var md = $(window).width() < 1023,
      sm = $(window).width() < 767

  function replaceElementAfter(where, from) {
    where.append(from)
  }

  function replaceElementBefore(where, from) {
    where.prepend(from)
  }

  if(md) {
    replaceElementAfter($('.card-mosaic__spot') , $('.card-mosaic__item_replacer'))
    replaceElementAfter($('.dropdown-filters__list'), $('.sidebar__section_gray'))
    replaceElementAfter($('.dropdown-filters__list'), $('.sidebar__section_filter'))
    replaceElementAfter($('.card-info__bottom'), $('.product-widget'))
  }


});
