jQuery(function ($){

  function showTopSearch() {
    $('.row-icons-block__search').on('click', function() {

        $('.top-search').slideDown().addClass('active')
        $('body').addClass('overlay');
        $('.top-search__input').focus();

    });

    $('.top-search__close').on('click', function() {

      $('.top-search').slideUp().removeClass('active')
      $('body').removeClass('overlay');

    });

    $(document).on('mouseup', function (e) {

      if ( $('.top-search').hasClass('active') ) {
        var elem = $('.top-search');
        if (!elem.is(e.target) && elem.has(e.target).length === 0) {

          $('.top-search').slideUp().removeClass('active')
          $('body').removeClass('overlay');

        }

      }

    });
  }

  showTopSearch();

});