jQuery(function ($){

  $('.light-tabs__item').on('click', function() {

    if($(this).not('active')) {
      $('.light-tabs__item').removeClass('active')
      $(this).addClass('active')
    }  

  });

});