jQuery(function ($){

  $('.amount__ctrl_plus').each(function(i,item) {
      item.addEventListener('click', function () {

        var x = +$('.amount__input').eq(i).val();

        x = x + 1;
        $('.amount__input').eq(i).val(x);
        saveQuantity($('.amount__input').eq(i));
      });
    });

  $('.amount__ctrl_minus').each(function(i,item) {
    item.addEventListener('click', function () {

      var x = +$('.amount__input').eq(i).val();

        x = x - 1;
        if (x < 1) {
            x = 1;
        }
        $('.amount__input').eq(i).val(x);
      saveQuantity($('.amount__input').eq(i));
    });
  });

  $('.amount__input').on('input', function(){
    console.log($(this).val());
    saveQuantity(this);
  });

  function saveQuantity(el){
    let id = $(el).attr('data-id');
    console.log(el, id);
    let val = $(el).val();
    $.ajax({
      url: `/cart/update/${id}`,
      data: {item_link: {quantity: val}},
      format: 'json',
      method: "PATCH",
    }).done(function(data){
      console.log(data)
    })
  };

});