jQuery(function ($){

  function closeWidgetBehavior(widgetItem, widgetContent, closeBtn) {

    closeBtn.on('click', function() {
      widgetItem.removeClass('active').addClass('disable');
      widgetContent.removeClass('active').addClass('disable');
      $('body').removeClass('overlay');
    });

      $(document).on('mouseup', function (e) {

        if ((widgetItem).hasClass('active')) {
          var elem = (widgetItem);
          if (!elem.is(e.target) && elem.has(e.target).length === 0) {

            widgetItem.removeClass('active').addClass('disable');
            widgetContent.removeClass('active').addClass('disable');
            $('body').removeClass('overlay');

          }

        }

      });

  }

  closeWidgetBehavior(
    $('#widget-item'),
    $('.widget-item__content'),
    $('.widget-item__icon_close')
  )

});