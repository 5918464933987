jQuery(function ($){

  $('.page-menu__list-item_catalog').on('click', function() {

      $('.catalog-menu').slideDown().addClass('active');
      $('body').addClass('overlay');

  });


  $(document).on('mouseup', function (e){ 
    if($('.catalog-menu').hasClass('active')) {
      var elem = $('.catalog-menu'); 
      if (!elem.is(e.target) && elem.has(e.target).length === 0) { 

        elem.slideUp().removeClass('active'); 
        $('body').removeClass('overlay');

      }    
    }

	  });
  });