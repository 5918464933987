jQuery(function ($){
 
  function setHeightForTableItem (tableItem) {

    var itemHeights = []

    tableItem.each(function(i, item) {
      itemHeights.push($(item).height())
    });
    
    var MaxValue = (Math.max.apply(null, itemHeights))

    tableItem.each(function(i, item) {
     $(item).height(MaxValue);
    });
    
  }

  var tableItemLenght = $('.compare__col-heading-item').length;

  for(let i = 0; i < tableItemLenght; i++) {
    setHeightForTableItem ($(`[data-row = ${i} ]`))
  }
    
});