jQuery(function ($){

  function showTextArea(btn, content) {
    btn.on('click', function() {

      if(content.hasClass('active')) {
        content.removeClass('active').slideUp();
      } else {
        content.addClass('active').slideDown();
      }

    })
  }

  showTextArea (
    $('.c-form__btn'),
    $('.c-form__input_textarea')
  )
  showTextArea (
    $('.form__btn'),
    $('.form__input_textarea')
  )

});