jQuery(function ($){

  function showSearch(input, hiddenInput, InputFocus, closeBtn) {
    input.on('click', function() {
      hiddenInput.show(200);

      InputFocus.focus();
    });

    closeBtn.on('click', function() {
      hiddenInput.hide(200);
    });
  }

  showSearch(
    $('.filters__search-zone'),
    $('.filters__item_hidden'),
    $('.filters__item_hidden input'),
    $('.filters__close-icon')
  )

});