// import Swiper from "swiper";
// core version + navigation, pagination modules:
// import Swiper, { Navigation, Pagination } from 'swiper/core';
// // configure Swiper to use modules
// Swiper.use([Navigation, Pagination]);
jQuery(function ($){

  // const swiper = new Swiper('.swiper-container', {
  //   // Optional parameters
  //   direction: 'horizontal',
  //   loop: true,

  //   // If we need pagination
  //   // pagination: {
  //   //   el: '.swiper-pagination',
  //   // },

  //   // Navigation arrows
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },

  //   // And if we need scrollbar
  //   // scrollbar: {
  //   //   el: '.swiper-scrollbar',
  //   // },
  // });

  const infoSldier = new Swiper('.info-slider__slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // Navigation arrows
    navigation: {
      nextEl: '.info-slider__nav-btn_next',
      prevEl: '.info-slider__nav-btn_prev',
    },

  });

  const longSlider = new Swiper('.long-slider__container', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    // Responsive breakpoints
    breakpoints: {
      1440: {
        slidesPerView: 6,
        spaceBetween: 30
      },
      1280: {
        spaceBetween: 24,
        slidesPerView: 6
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      375: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    },

    // Navigation arrows
    navigation: {
      nextEl: '.long-slider__nav-btn_next',
      prevEl: '.long-slider__nav-btn_prev',
    },

  });

  if($(window).width() > 768) {
    const pageSlider = new Swiper('.page-slider__container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      breakpoints: {
        1440: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        1280: {
          spaceBetween: 30,
          slidesPerView: 4
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        375: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      },
  
      // Navigation arrows
      navigation: {
        nextEl: '.page-slider__nav-btn_next',
        prevEl: '.page-slider__nav-btn_prev',
      },
  
    });
  }

  const compareSlider = new Swiper('.compare__slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    breakpoints: {
      1440: {
        slidesPerView: 4
      },
      1280: {
        slidesPerView: 4
      },
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      375: {
        slidesPerView: 2
      }
    },

    // Navigation arrows
    navigation: {
      nextEl: '.compare-slider-navigate__arrow_next',
      prevEl: '.compare-slider-navigate__arrow_prev',
    },

  });

});
