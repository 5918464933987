jQuery(function ($){

  function replaceHtml(target, place) {

    target.each(function(i, item) {

      var targetHtml = $(item).html();

      console.log(targetHtml)

      place.eq(i).html(targetHtml)

    }); 

   

  }

  replaceHtml(
    $('.target-html'),
    $('.target-html-place')
  )

});