jQuery(function ($){

  gChangeTabs = function (btn, tabItem) {

    function hideAllTabs() {
      tabItem.hide();
      btn.removeClass('active')
    }
  
    btn.each(function(i, item) {
      item.addEventListener('click', function() {

        hideAllTabs();

        item.classList.add('active')
        tabItem.eq(i).show();
      });
    });
  }

  gChangeTabs(
    $('.tabs__title'),
    $('.tabs__item')
  )

  gChangeTabs(
    $('.light-tab__item'),
    $('.light-tab__content')
  )

});