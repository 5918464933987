jQuery(function ($){

  function dropdown (btn, dropdownList, icon) {
    btn.each(function(i, item) {
      item.addEventListener('click', function() {
        if(dropdownList.eq(i).hasClass('active')) {
          dropdownList.eq(i).slideUp();
          dropdownList.eq(i).removeClass('active').addClass('disable')
          icon.eq(i).removeClass('connect__icon_active')
        } else {
          dropdownList.eq(i).slideDown();
          dropdownList.eq(i).addClass('active')
          icon.eq(i).addClass('connect__icon_active')
        }
    
      });
    });
  }

  function dropdownClose (btn, dropdownList, close) {
    btn.each(function(i, item) {
      item.addEventListener('click', function() {
        if(dropdownList.eq(i).hasClass('active')) {
          dropdownList.eq(i).slideUp();
          dropdownList.eq(i).removeClass('active')
        } else {
          dropdownList.eq(i).slideDown();
          dropdownList.eq(i).addClass('active')
        }   
      });
    });
    close.on('click', function() {
      dropdownList.slideUp();
    });
  }

  dropdown($('.connect__dropdown-name'), $('.connect__dropdown-list'), $('.connect__icon'))

  let dropdDownTitleText = $('.connect__dropdown-name-text')
      dropDownList = $('.connect__dropdown-list'),
      dropDownIcon = $('.connect__icon'),
      dropDownTitleIcon = $('.connect__dropdown-icon'),
      dropDownItem = $('.connect__dropdown-list-item')
      targetPhone = $('a.header-info__text')
      targetPhone2 = $('a.connect__text.connect__text_black')

  dropDownTitleIcon.on('click', function() {
    dropDownList.slideUp();
    dropDownList.removeClass('active')
    dropDownIcon.removeClass('connect__icon_active')
  });

  dropDownItem.on('click', function() {
    dropdDownTitleText.html($(this).html())
    targetPhone.html($(this).attr('data-phone'))
    targetPhone.attr('href', $(this).attr('data-link'))
    targetPhone2.html($(this).attr('data-phone'))
    targetPhone2.attr('href', $(this).attr('data-link'))
    dropDownList.slideUp();
    dropDownList.removeClass('active')
    dropDownIcon.removeClass('connect__icon_active')
  });

  dropdown($('.page-menu__list-item_hidden'), $('.page-menu__hidden-list'), $('.page-menu__list-icon'));
  dropdown($('.dropdown__inner') , $('.dropdown__list'), $('.dropdown__icon'));
  dropdownClose($('.dropdown-filters__wrapper'), $('.dropdown-filters__list'), $('.dropdown-filters__close'));
});