jQuery(function ($){

  function showProductWidget() {

    function toggle() {
      $('.product-widget').toggleClass('active');
      $('.card-info__wrapper').toggleClass('active');
      $('.card-info__more-btn-icon_dark').toggleClass('active')
      $('.card-info__more-btn-text').toggleClass('active')
      $('.card-info__accordion').toggleClass('active')
    }

    function accordionSlideUp() {
      $('.accordion__inner').removeClass('active');
      $('.accordion__icon').removeClass('active');
      $('.accordion__list').slideUp();
    }

    $('.card-info__more-btn').on('click', function() {
      toggle();
      accordionSlideUp()
    });

    $('.points__item').each(function(i, item) {

      item.addEventListener('mouseover', function() {
        $('.points__tooltip').eq(i).fadeIn(50)
        $('.product-widget__item-wrapper').eq(i).addClass('active')
      });

      item.addEventListener('mouseout', function() {
        $('.points__tooltip').eq(i).fadeOut(50)
        $('.product-widget__item-wrapper').eq(i).removeClass('active')
      });

    });

    $('.product-widget__item-wrapper').each(function(i, item) {

      item.addEventListener('mouseover', function() {
        $('.points__tooltip').eq(i).show()
        $('.product-widget__item-wrapper').eq(i).addClass('active')
      });

      item.addEventListener('mouseout', function() {
        $('.points__tooltip').eq(i).hide()
        $('.product-widget__item-wrapper').eq(i).removeClass('active')
      });

    });

  }

  showProductWidget()

});