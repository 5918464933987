jQuery(function ($){

  $('#menu-btn').on('click', function() {
    if($('.hidden-menu__lvl_1').hasClass('hidden-menu__lvl_1-active')) {
      $('.hidden-menu__lvl_1').addClass('hidden-menu__lvl_1-disable')
      $('.hidden-menu__list_lvl1').addClass('hidden-menu__list_lvl1_disable')
      $('.hidden-menu__lvl_1').removeClass('hidden-menu__lvl_1-active')
      $('.hidden-menu__list_lvl1').removeClass('hidden-menu__list_lvl1_active')
      $('body').toggleClass('overlay')
      $('.hidden-menu__btn_lvl1').toggleClass('hidden-menu__btn_lvl1_active')

    } else {
      $('.hidden-menu__lvl_1').removeClass('hidden-menu__lvl_1-disable')
      $('.hidden-menu__list_lvl1').removeClass('hidden-menu__list_lvl1_disable')
      $('.hidden-menu__lvl_1').addClass('hidden-menu__lvl_1-active')
      $('.hidden-menu__list_lvl1').addClass('hidden-menu__list_lvl1_active')
      $('body').toggleClass('overlay')
      $('.hidden-menu__btn_lvl1').toggleClass('hidden-menu__btn_lvl1_active')
    }
  }); 

  $('.hidden-menu__btn_lvl1').on('click', function() {
    $('.hidden-menu__lvl_1').removeClass('hidden-menu__lvl_1-active')
    $('.hidden-menu__list_lvl1').removeClass('hidden-menu__list_lvl1_active')
    $('.hidden-menu__lvl_1').addClass('hidden-menu__lvl_1-disable')
    $('.hidden-menu__list_lvl1').addClass('hidden-menu__list_lvl1_disable')
    $('body').toggleClass('overlay')
    $('.hidden-menu__btn_lvl1').toggleClass('hidden-menu__btn_lvl1_active')
  });

  $('.hidden-menu__item_catalog').on('click', function() {
    if($('.hidden-menu__lvl_2').hasClass('hidden-menu__lvl_2-active')) {
      $('.hidden-menu__lvl_2').addClass('hidden-menu__lvl_2-disable')
      $('.hidden-menu__list_lvl2').addClass('hidden-menu__list_lvl2_disable')
      $('.hidden-menu__lvl_2').removeClass('hidden-menu__lvl_2-active')
      $('.hidden-menu__list_lvl2').removeClass('hidden-menu__list_lvl2_active')
      $('.hidden-menu__btn_lvl1').toggleClass('hidden-menu__btn_lvl1_active')

    } else {
      $('.hidden-menu__lvl_2').removeClass('hidden-menu__lvl_2-disable')
      $('.hidden-menu__list_lvl2').removeClass('hidden-menu__list_lvl2_disable')
      $('.hidden-menu__lvl_2').addClass('hidden-menu__lvl_2-active')
      $('.hidden-menu__list_lvl2').addClass('hidden-menu__list_lvl2_active')
      $('.hidden-menu__btn_lvl2').toggleClass('hidden-menu__btn_lvl2_active')
    }
  });

  $('.hidden-menu__title-icon').on('click', function() {
    $('.hidden-menu__lvl_2').addClass('hidden-menu__lvl_2-disable')
    $('.hidden-menu__list_lvl2').addClass('hidden-menu__list_lvl2_disable')
    $('.hidden-menu__lvl_2').removeClass('hidden-menu__lvl_2-active')
    $('.hidden-menu__list_lvl2').removeClass('hidden-menu__list_lvl2_active')
    $('.hidden-menu__btn_lvl2').toggleClass('hidden-menu__btn_lvl2_active')
  });

  $('.hidden-menu__btn_lvl2').on('click', function() {
    $('.hidden-menu__lvl_2').removeClass('hidden-menu__lvl_2-active')
    $('.hidden-menu__list_lvl2').removeClass('hidden-menu__list_lvl2_active')
    $('.hidden-menu__lvl_2').addClass('hidden-menu__lvl_2-disable')
    $('.hidden-menu__list_lvl2').addClass('hidden-menu__list_lvl2_disable')
    $('.hidden-menu__btn_lvl2').toggleClass('hidden-menu__btn_lvl2_active')
  });

  $(document).on('mouseup', function (e){ 

    if($('.hidden-menu__lvl_1').hasClass('hidden-menu__lvl_1-active')) {
      var elem = $('.hidden-menu__lvl_1'); 
      if (!elem.is(e.target) && elem.has(e.target).length === 0) { 

        $('.hidden-menu__lvl_1').removeClass('hidden-menu__lvl_1-active')
        $('.hidden-menu__list_lvl1').removeClass('hidden-menu__list_lvl1_active')
        $('.hidden-menu__lvl_1').addClass('hidden-menu__lvl_1-disable')
        $('.hidden-menu__list_lvl1').addClass('hidden-menu__list_lvl1_disable')
        $('body').toggleClass('overlay')
        $('.hidden-menu__btn_lvl1').toggleClass('hidden-menu__btn_lvl1_active')

      }  
      
    }

    if($('.hidden-menu__lvl_2').hasClass('hidden-menu__lvl_2-active')) {
      var elem = $('.hidden-menu__lvl_2'); 
      if (!elem.is(e.target) && elem.has(e.target).length === 0) { 
        
        $('.hidden-menu__lvl_2').removeClass('hidden-menu__lvl_2-active')
        $('.hidden-menu__list_lvl2').removeClass('hidden-menu__list_lvl2_active')
        $('.hidden-menu__lvl_2').addClass('hidden-menu__lvl_2-disable')
        $('.hidden-menu__list_lvl2').addClass('hidden-menu__list_lvl2_disable')
        $('.hidden-menu__btn_lvl2').toggleClass('hidden-menu__btn_lvl2_active')

      }  
      
    }

  
  });

});