jQuery(function ($){

  var xl = $(window).width() >= 1280,
      lg = $(window).width() < 1279 && $(window).width() >= 1024,
      md = $(window).width() < 1023 && $(window).width() >= 768,
      sm = $(window).width() < 767

  function showNavigation() {

    if( $('.compare__col').length > 5 && xl) {

      $('.compare__navigate').removeClass('disable').addClass('active');
      $('.compare__adaptive-item').height($('.compare__adaptive-item').height() + 81)
      $('.compare-slider-navigate__total').html(' ' + (($('.compare__col').length) - 1))

    } else if( $('.compare__col').length > 4 && lg) {

      $('.compare__navigate').removeClass('disable').addClass('active');
      $('.compare__adaptive-item').height($('.compare__adaptive-item').height() + 81)
      $('.compare-slider-navigate__total').html(' ' + (($('.compare__col').length) - 1))
      $('.compare-slider-navigate__count').html(3)

    } else if( $('.compare__col').length > 3 && md)  {

      $('.compare__navigate').removeClass('disable').addClass('active');
      $('.compare__adaptive-item').height($('.compare__adaptive-item').height() + 81)
      $('.compare-slider-navigate__total').html(' ' + (($('.compare__col').length) - 1))
      $('.compare-slider-navigate__count').html(2)

    } else if(sm) {

      $('.compare__navigate').removeClass('disable').addClass('active');
      $('.compare-slider-navigate__count').html(2);
      $('.compare-slider-navigate__total').html(' ' + (($('.compare__col').length) - 1))

    } else {

      $('.compare__navigate').removeClass('active').addClass('disable');

    }

  }

  function disableNavigation() {
    $('.compare__adaptive-item').height($('.compare__adaptive-item').height() - 81)
    $('.compare__navigate').addClass('disable').removeClass('active');
  }

  function deleteCol() {

    $('.compare-product__close-btn').on('click', function(){
      let $col = $(this).closest('.compare__content-col');
      let id = $col.attr('data-id');

      $col.fadeOut(300, function(){
        $col.remove();
        $.ajax({
          url: `/comparisons/remove_item/${id}`,
          format: 'json',
          method: "DELETE",
        }).done(function(data){
          console.log(data)
        })

        $('.compare-slider-navigate__count').html($('.compare__content-col').length)
        $('.compare-slider-navigate__total').html(' ' + (($('.compare__col').length) - 1))

        checkNavigation();
      });
    });

    function checkNavigation(){
      let size = $('.compare__content-col').length;
      if(size === 4 && xl) {
        disableNavigation()
      } else if(size === 3 && lg) {
        disableNavigation()
      } else if(size === 2 && md) {
        disableNavigation()
      }
    }
  }

  function positionHeadingTitle() {
    $('.compare__content-item').each(function(i, item) {
      var pos = ($(item).offset())
      $('.mobile-compare-heading__item').eq(i).offset({top: pos.top, left: pos.left})
    });
  }

  function markSimilarItems (rowItem) {

      rowItem.each(function(i, item) {

        if(rowItem.eq(0).html() == $(item).html()) {
          $(item).addClass('disable');
        } 
  
      });
       
  }

  function switchDiffParametr() {
    $('.compare__diff-radio').on('change', function() {

      for(let i = 0; i < $('.compare__col-heading-item').length; i++) { 

        var disable = $(`.compare__content-item[data-row = ${i}] > .disable`).length
        var row = $(`.compare__content-item[data-row=${i}]`).length

        if(disable == row && i > 0) {
          $(`[data-row=${i}]`).hide();
        } else {
          $(`.compare__content-item[data-row=${i}]`).removeClass('disable');
        }

      }

    });
  }

  function switchAllParametr() {
    $('.compare__all-radio').on('change', function() {

      for(let i = 0; i < $('.compare__col-heading-item').length; i++) { 

        $(`[data-row=${i}]`).show();
      }

    });
  }

  function calculateItems () {

    if(sm) {

      if($('.compare__content-col').length < 2) {
        $('.compare-slider-navigate__count').html($('.compare__content-col').length)
      }

    }

  }

  showNavigation();
  deleteCol();
  positionHeadingTitle();

  for(let i = 0; i < $('.compare__col-item').length; i++) {
    markSimilarItems(
      $(`.compare__content-item[data-row = ${i}] > .compare__text`)
    );
  }

  switchDiffParametr()
  switchAllParametr()
  calculateItems()

});