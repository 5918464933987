jQuery(function ($){

slider (
  $('.half-slider__image'),
  $('.half-slider__item'),
  $('.half-slider__nav-btn_next'),
  $('.half-slider__nav-btn_prev')
)

singleSlider (
  $('.content-block__image'),
  $('.content-block__score_count'),
  $('.content-block__score_total'),
  $('.content-block__nav-arrow_next'),
  $('.content-block__nav-arrow_prev')
)

singleSlider (
  $('.modal-slider__image'),
  $('.modal-slider__score_count'),
  $('.modal-slider__score_total'),
  $('.modal-slider__nav-arrow_next'),
  $('.modal-slider__nav-arrow_prev')
)

singleSlider (
  $('.full-screen-image'),
  $('.acd-card__score_count'),
  $('.acd-card__score_total'),
  $('.acd-card__nav-arrow_next'),
  $('.acd-card__nav-arrow_prev')
)

let slideIndex = 1,
  slides = $('.full-height-block__slide'),
  slides2 = $('.full-height-block__slide-2')
  next = $('.full-height-block__nav-arrow_next'),
  prev = $('.full-height-block__nav-arrow_prev');

  $('#full-height-block__slide-total').html(slides.length)

function showSlides(n) {

    if (n > slides.length) {
        slideIndex = 1;
    } else if (n < 1) {
        slideIndex = slides.length;
    }

    slides.hide().addClass('full-height-block__hidden');
    slides2.hide().addClass('full-height-block__hidden');

    slides.eq(slideIndex - 1).fadeIn().removeClass('full-height-block__hidden');
    slides2.eq(slideIndex - 1).fadeIn().removeClass('full-height-block__hidden');
}
showSlides(slideIndex);

function plusSlides(n) {
  showSlides(slideIndex += n);
  // slides.eq(slideIndex - 1).fadeIn();;
  // slides2.eq(slideIndex - 1).fadeIn();;
}

prev.on('click', function() {
  if($(this).hasClass('animation-down')) {
    $(this).removeClass('animation-down').addClass('animation-up');
    slides.eq(slideIndex - 1).stop(true, true).fadeOut()
    slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
  } 

  $(this).removeClass('animation-up').addClass('animation-down');
  
  plusSlides(-1);
  $('#full-height-block__slide-count').html(slideIndex)
});

next.on('click', function() {
  if($(this).hasClass('animation-down')) {
    $(this).removeClass('animation-down').addClass('animation-up');
    slides.eq(slideIndex - 1).stop(true, true).fadeOut()
    slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
  } 

  $(this).removeClass('animation-up').addClass('animation-down');

  plusSlides(1);
  $('#full-height-block__slide-count').html(slideIndex)
});



function slider (_slides, _slides2, _next, _prev, ) { 

  let slideIndex = 1,
    slides = _slides,
    slides2 =  _slides2,
    next = _next,
    prev =  _prev;
    
  function showSlides(n) {
  
      if (n > slides.length) {
          slideIndex = 1;
      } else if (n < 1) {
          slideIndex = slides.length;
      }
  
      slides.hide().addClass('hidden');
      slides2.hide().addClass('hidden');
  
      slides.eq(slideIndex - 1).fadeIn().removeClass('hidden');
      slides2.eq(slideIndex - 1).fadeIn().removeClass('hidden');
  }
  showSlides(slideIndex);
  
  function plusSlides(n) {
    showSlides(slideIndex += n);
    // slides.eq(slideIndex - 1).fadeIn();;
    // slides2.eq(slideIndex - 1).fadeIn();;

  }
  
  prev.on('click', function() {

      if($(this).hasClass('animation-down')) {
        $(this).removeClass('animation-down').addClass('animation-up');
        slides.eq(slideIndex - 1).stop(true, true).fadeOut()
        slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
      } 

      $(this).removeClass('animation-up').addClass('animation-down');
      
      plusSlides(-1);

  });
  
  next.on('click', function() {

    if($(this).hasClass('animation-down')) {
      $(this).removeClass('animation-down').addClass('animation-up');
      slides.eq(slideIndex - 1).stop(true, true).fadeOut()
      slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
    } 

    $(this).removeClass('animation-up').addClass('animation-down');

    plusSlides(1);

  });

}

function singleSlider (_slides, _count, _total, _next, _prev, ) { 

  let slideIndex = 1,
    slides = _slides,
    count =  _count,
    total =  _total,
    next = _next,
    prev =  _prev;

  total.html(slides.length)
    
  function showSlides(n) {
  
      if (n > slides.length) {
          slideIndex = 1;
      } else if (n < 1) {
          slideIndex = slides.length;
      }
  
      slides.hide().addClass('hidden');
  
      slides.eq(slideIndex - 1).fadeIn().removeClass('hidden');
  }
  showSlides(slideIndex);
  
  function plusSlides(n) {
    showSlides(slideIndex += n);
    // slides.eq(slideIndex - 1).fadeIn();;
  }
  
  prev.on('click', function() {
    if($(this).hasClass('animation-down')) {
      $(this).removeClass('animation-down').addClass('animation-up');
      slides.eq(slideIndex - 1).stop(true, true).fadeOut()
      slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
    } 

    $(this).removeClass('animation-up').addClass('animation-down');
    plusSlides(-1);
    count.html(slideIndex)
  });
  
  next.on('click', function() {
    if($(this).hasClass('animation-down')) {
      $(this).removeClass('animation-down').addClass('animation-up');
      slides.eq(slideIndex - 1).stop(true, true).fadeOut()
      slides2.eq(slideIndex - 1).stop(true, true).fadeOut()
    } 

    $(this).removeClass('animation-up').addClass('animation-down');
    plusSlides(1);
    count.html(slideIndex)
  });
}


});
