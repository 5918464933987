jQuery(function ($){

  gAccordion = function (btn, list, icon) {

    function closeAllAccordions() {
      list.slideUp()
      btn.removeClass('active');
      icon.removeClass('active');
    }

    btn.each(function(i, item) {

      item.addEventListener('click', function() {
        
        if ( item.classList.contains('active') ) {

          closeAllAccordions();

        } else {
          
          closeAllAccordions();

          item.classList.add('active');

          list.eq(i).slideDown()
          icon.eq(i).addClass('active');

        }

      });

    });

  }

  gAccordion (
    $('.accordion__inner'),
    $('.accordion__list'),
    $('.accordion__icon')
  )

});