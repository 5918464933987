jQuery(function ($){

  setError()

  function setError() {
    $('select').on('focus', function() {
      $(this).closest('.form__select-wrapper').addClass('has-focus')
    }); 

    $('select').on('focusout', function() {
      $(this).closest('.form__select-wrapper').removeClass('has-focus')
    }); 
  }

});