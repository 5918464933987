jQuery(function ($){

  function openContent(btn, content) {
    btn.on('click', function() {
      if(content.hasClass('active')) {
        content.removeClass('active').slideUp();
      } else {
        content.addClass('active').slideDown();
      }
    });
  }

  openContent(
    $('.acd-card__btn'),
    $('.acd-card__content_hidden')
  )

});