jQuery(function ($){
 $('.position-inputs-wrapper input[type="color"]').on('input', function(){
   let color = $(this).val();
   let $point = $(this).closest('.position-inputs-wrapper').find('.points__item');
   $point.css('background-color', color)
 })

 $('.position-inputs-wrapper .item_layers_left input').on('input', function(){
   let left = $(this).val();
   let $point = $(this).closest('.position-inputs-wrapper').find('.points__item');
   $point.css('left', `${left}%`)
 })

 $('.position-inputs-wrapper .item_layers_top input').on('input', function(){
   let top = $(this).val();
   let $point = $(this).closest('.position-inputs-wrapper').find('.points__item');
   $point.css('top', `${top}%`)
 })
});