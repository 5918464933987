jQuery(function ($){

  function consoleLog() {
    console.log('Done!');
  }

  function hideItems(sol_id){
    $('#items .item-card').each(function(){
      console.log($(this).attr('data-sol'))
      let arr = $(this).attr('data-sol').split(',')
      if (arr.includes(sol_id)) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }

  $('#solution-tag .meta__icon').on('click', function(e){
    e.preventDefault();
    $(this).addClass('d-none')
    $(this).parent().find('.meta__text').html('Все отрасли')
    $('#items .item-card').show();
  })

  function printData(inputItem, inputData, dropDownList, dropDownIcon, title, hiddenTitle) {
    inputItem.each(function(i, item) {
      item.addEventListener('click', function() {

        let dataName = inputData.eq(i).attr('data-name');
        let dataSol = inputData.eq(i).attr('data-sol');
        title.html(dataName)
        $('#solution-tag .meta__text').html(dataName)
        $('#solution-tag .meta__icon').removeClass('d-none')

        dropDownList.slideUp().removeClass('active');
        dropDownIcon.removeClass('connect__icon_active');

        hiddenTitle.show();
        hideItems(dataSol);

        consoleLog();
      });
    });

  };

  printData(
    $('.sidebar__section_gray .dropdown__item'), 
    $('.sidebar__section_gray input'), 
    $('.sidebar__section_gray .dropdown__list'), 
    $('.sidebar__section_gray .dropdown__icon'), 
    $('.sidebar__section_gray .dropdown__title_large'),
    $('.sidebar__section_gray .dropdown__title_small')
  );

});
