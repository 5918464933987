jQuery(function() {
  var dataWithNewItem;
  var currentSelect;
  var $select2Elements = $('select.select2');
  enableSelect2($select2Elements);


  reEnableSelect2Cocoon = enableSelect2Cocoon;
  reEnableSelect2 = enableSelect2;

  enableSelect2Cocoon();

  function enableSelect2Cocoon() {
    $('.can-create-select2')
      .on('cocoon:before-insert', function(e,el_to_be_added) {
        // console.log('cocoon:before-insert');
        el_to_be_added.fadeIn();
      })
      .on('cocoon:after-insert', function(e, added_el) {
        // e.g. set the background of inserted task
        //added_el.css("background","red");
        // console.log('cocoon:after-insert', added_el);
        enableSelect2($(added_el).find('select.select2'));
      })
      .on('cocoon:before-remove', function(e, el) {
        // allow some time for the animation to complete
        $(this).data('remove-timeout', 1000);
        el.fadeOut('fast');
      });
  }

  function removeSelectedOption(select) {
    $(select).select2('val','');
  }

  function enableSelect2(elements, data) {
    if (elements.length === 0) {
      elements = $('select.select2');
    }

    $(elements).each(function (index, el) {
      bindSelect2ToEl(el, data);
    })
  }



  function bindSelect2ToEl(element, data) {
    var select2Options = {language: 'ru'};
    var createData = $(element).attr('data-create');

    if (data !== undefined) {
      Object.assign(select2Options, {data: data.results})
    }

    if (createData === 'true') {
      Object.assign(select2Options, { tags: true, createTag: createTagHandler })
    }

    $(element).select2(select2Options);

    // $(elements).on("select2:close", function (e) { console.log(e); });

    // if (createData === 'true') {
    //   // create listener only if allowed to create new records.
    //   $(element).on("select2:select", function(e) {
    //     e.preventDefault();
    //
    //     // todo: receive url through data attributes..
    //     var url = "/sec/clients/" + gon.client_id + "/user_account_tags",
    //       status = e.params.data.status,
    //       text = e.params.data.text,
    //       userAccountId = $(this).closest('div[data-user-account-id]').data('userAccountId');
    //
    //     dataWithNewItem = $(this).select2('data');
    //     currentSelect = $(this);
    //
    //     if (status === 'new') {
    //       $.ajax({
    //         type: "POST",
    //         url: url,
    //         data: {user_account_tag:{name: text}, user_account_id: userAccountId},
    //         dataType: 'json',
    //         cache: false,
    //         success: selSuccess,
    //         error: selError
    //       });
    //     }
    //   });
    // }
  }


  // function createTagHandler(params) {
  //   return {
  //     id: params.term,
  //     text: params.term,
  //     status: 'new'
  //   }
  // }

  // function selSuccess(data, status, xhr) {
  //   var id   = data.ua_tag.id,
  //     text = data.ua_tag.name;
  //
  //   // ReInitialize Current select with new selected option
  //   enableSelect2(currentSelect, data.current_data);
  //
  //
  //   $('#accordeon-main').prepend(data.tag_html);
  //
  // }

  // function selError(xhr, status, error) {
  //   console.log('Error');
  //   console.log(xhr);
  // }

  // function updateIDSelect2Data(data, id, text) {
  //   debugMsg('updateIDSelect2Data');
  //   var newData = {results:[]};
  //   $.each(data.results, function(i,el) {
  //     if (el.text === text) {
  //       el.id = id;
  //     }
  //
  //     newData.results.push(el)
  //   });
  //   return newData;
  // }

});