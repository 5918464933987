// app/javascript/packs/application.js

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

////// jQuery
var jQuery = require('jquery')
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

window.gAccordion
window.gChangeTabs

// for Rails CMS
require("@nathanvda/cocoon")

////// import the bootstrap javascript module
import "bootstrap"
// import the application.scss we created for the bootstrap CSS (if you are not using assets stylesheet)
// import "../stylesheets/application"

// Bootstrap 4 custom file input. For Rails CMS.
import bsCustomFileInput from 'bs-custom-file-input'
window.bsCustomFileInput = bsCustomFileInput;

////// Swiper
import Swiper, { Navigation, Pagination } from 'swiper/core';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
// make Swiper available everywhere
global.Swiper = Swiper;
window.Swiper = Swiper;

import 'select2'


////// Other custom JS
import "../js/jquery.matchHeight-min"
import "../js/repalce-element"
import "../js/slider"
import "../js/custom-slider"
import "../js/dropdown"
import "../js/hidden-menu"
import "../js/print-data"
import "../js/show-search"
import "../js/accordion"
import "../js/tabs"
import "../js/show-widget"
import "../js/show-product-widget"
import "../js/change-amount"
import "../js/show-textarea"
import "../js/open-content"
import "../js/set-height-table"
import "../js/compare-table"
import "../js/replace-html"
import "../js/open-catalog-menu"
import "../js/show-top-search"
import "../js/selectError"
import "../js/select2_init"
import "../js/change-light-tabs"
import "../js/scroll-to-id"
import "../js/change-fileinput-name"
import "../js/cms_points"
import "../js/custom_file_input"
import "../js/main"